import React, { useState, useEffect, useCallback } from "react";
import "fontsource-roboto";
import {
  BrowserRouter as Router,
  useLocation,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import BottomNav from "./components/bottom_nav";
import Home from "./pages/home";
import Login from "./pages/log_in";
import NewContributionScreen from "./components/new_contribution";
import Profile from "./pages/profile";
import Prompts from "./pages/prompts";
import SignUp from "./pages/sign_up";
const axios = require("axios");

const App = () => {
  const userKey = sessionStorage.getItem("userKey");
  const isMobile = useMediaQuery("(max-width:600px)");
  const useStyles = makeStyles({
    page: {
      position: "relative",
      minHeight: "100vh",
    },
    root: {
      backgroundColor: "rgb(26, 26, 26)",
    },
    bgYellow: {
      backgroundColor: "rgb(253, 193, 0)",
    },
    textYellow: {
      color: "rgb(253, 193, 0)",
    },
    bgWhite: {
      backgroundColor: "white",
    },
    textWhite: {
      color: "rgb(253, 193, 0)",
    },
    navRoot: {
      //   backgroundColor: "rgb(253, 193, 0)",
      borderTop: "solid",
      borderTopWidth: "0.5px",
      borderTopColor: "rgba(26, 26, 26, 0.5)",
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
    icon: {
      color: "rgb(253, 193, 0)",
      width: "40px",
      height: "40px",
    },
    iconBox: {
      paddingTop: "4px",
      paddingBottom: "16px",
    },
  });
  // const navTheme = () =>
  //   createMuiTheme({
  //     overrides: {
  //       MuiSvgIcon: {
  //         root: {
  //           color: "white",
  //         },
  //       },
  //     },
  //   });
  const classes = useStyles();
  const [reloadUserData, setReloadUserData] = useState(false);
  const triggerReload = useCallback(
    () => setReloadUserData((prev) => !prev),
    []
  );
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const token = sessionStorage.getItem("userKey");
    const id = sessionStorage.getItem("userId");
    const getUserData = async () => {
      const response = await axios({
        method: "get",
        url: `https://kuulpeeps-contributors-api.herokuapp.com/api/users/${id}`,
        headers: { Authorization: token },
      });
      console.log({ userData: response.data.data[0] });
      setUserData(() => response.data.data[0]);
    };
    (async () => {
      await getUserData();
    })();
  }, [reloadUserData]);
  const [isMakingContribution, setIsMakingContribution] = useState(false);
  const toggleIsMakingContribution = () => {
    return setIsMakingContribution((prev) => !prev);
  };
  return (
    <Box
      style={{
        padding: isMobile ? "0" : "0 25vw",
        backgroundColor: "rgba(26, 26, 26, 0.5)",
      }}
    >
      <Router>
        {!userKey && <Redirect to="/" />}

        {/* --------------------------------- SWITCH --------------------------------- */}

        <Switch>
          <Route path="/profile">
            <Profile
              theme={classes}
              userData={userData}
              reloadUserData={triggerReload}
            />
          </Route>
          <Route path="/prompts">
            <Prompts
              theme={classes}
              toggleIsMakingContribution={toggleIsMakingContribution}
            />
          </Route>
          <Route path="/home">
            <Home
              theme={classes}
              userData={userData}
              toggleIsMakingContribution={toggleIsMakingContribution}
              reloadUserData={triggerReload}
            />
          </Route>
          <Route path="/login">
            <Login theme={classes} />
          </Route>
          <Route path="/">
            <SignUp theme={classes} />
          </Route>
        </Switch>

        {/* ----------------- BOTTOM NAV AND NEW CONTRIBUTION SCREEN ----------------- */}
        <Box>
          <BottomNav />
          <NewContributionScreen
            isMakingContribution={isMakingContribution}
            toggleScreen={toggleIsMakingContribution}
            userData={userData}
          />
        </Box>
      </Router>
    </Box>
  );
};

export default App;
