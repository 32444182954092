import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import Heading from "../../../components/heading";
import ListItem from "../../../components/list_item";
import * as Constants from "../../../utils/constants";
const axios = require("axios");

const HomeItems = ({ userData, paymentHistory, contributions }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [contributionToDelete, setContributionToDelete] = useState("");
  const toggleIsDeleting = (id) => {
    id && setContributionToDelete(() => id);
    setIsDeleting((prev) => !prev);
  };
  const deleteContribution = async (values) => {
    const token = sessionStorage.getItem("userKey");
    const deletedContribution = await axios({
      method: "delete",
      url: "https://kuulpeeps-contributors-api.herokuapp.com/api/contributions",
      headers: { Authorization: token },
      data: { _id: contributionToDelete },
    });
    console.log(deletedContribution);
    window.location.reload();
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      // borderTop: "1px solid rgb(26, 26, 26)",
      width: "100%",
    },
    accordionRoot: {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    accordionDetails: {
      maxHeight: "320px",
      overflow: "scroll",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    icon: {
      color: "rgb(26, 26, 26)",
    },
    summary: {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Box
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Heading colorB text="Earnings & Payments" variant="h5" />
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box pb={2} style={{ width: "100%" }}>
            <Box pb={1}>
              <Divider variant="fullWidth" />
            </Box>
            {paymentHistory &&
              paymentHistory.map((item) => {
                const isPayment = item.Action.ConfirmedPayment;
                return (
                  <ListItem
                    key={paymentHistory.indexOf(item)}
                    listTextA={
                      isPayment ? "Payment Received" : "Balance Updated"
                    }
                    listTextB={
                      isPayment ? `GHC${item.Value}.00` : `+GHC${item.Value}.00`
                    }
                    listTextC={moment(item.Date).format(
                      "ddd, MMMM Do YYYY, H:mm"
                    )}
                  />
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Box
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Heading center colorB text="Ranking" variant="h5" />
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box pb={2} style={{ width: "100%" }}>
            <Box pb={1}>
              <Divider variant="fullWidth" />
            </Box>
            <ListItem
              listTextA="Your Rank Data"
              listTextB={userData.GlobalRank}
              listTextC="Global Rank"
              listTextD={userData.CampusRank}
              listTextE="Campus Rank"
            />
            <ListItem
              listTextA="Your Approval Stats"
              listTextB={userData.ApprovedContributions}
              listTextC="Approved Contributions"
              listTextD={`${userData.ApprovalRate?.toFixed(0)}%`}
              listTextE="Contribution Approval Rate"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Box
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Heading
              center
              colorB
              text={`Contributions (${contributions.length})`}
              variant="h5"
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box pb={2} style={{ width: "100%" }}>
            <Box pb={1}>
              <Divider variant="fullWidth" />
            </Box>
            {contributions.length === 0 && (
              <ListItem listTextB="Make Your First Contribution And Start Earning!" />
            )}
            {contributions.map((contribution) => {
              return (
                <ListItem
                  action={() => {
                    if (contribution.IsPublished) {
                      return window.open(contribution.ArticleLink, "_blank");
                    }
                    if (contribution.IsNew || contribution.IsPending) {
                      toggleIsDeleting(contribution._id);
                    }
                    return;
                  }}
                  deletable={contribution.IsPending || contribution.IsNew}
                  iconB={contribution.IsPublished}
                  key={contributions.indexOf(contribution)}
                  hasRejection={
                    contribution.RejectionMessage !== "Not Rejected"
                  }
                  listTextA={
                    (contribution.IsNew && "Pending") ||
                    (contribution.IsPending && "Pending") ||
                    (contribution.IsApproved && "Approved") ||
                    (contribution.IsPublished && "Published") ||
                    (contribution.IsRejected && "Rejected")
                  }
                  listTextB={contribution.Title}
                  listTextC={moment(contribution.DateCreated).format(
                    "ddd, MMMM Do YYYY, H:mm"
                  )}
                  listTextD={
                    contribution.RejectionMessage !== "Not Rejected" &&
                    contribution.RejectionMessage
                  }
                />
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Box
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Heading center colorB text="FAQs & Help" variant="h5" />
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box pb={2} style={{ width: "100%" }}>
            <Box pb={1}>
              <Divider variant="fullWidth" />
            </Box>
            {Constants.guidelines.map((item) => {
              return (
                <ListItem
                  key={Constants.guidelines.indexOf(item)}
                  listTextB={item}
                />
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* -------------------------------------------------------------------------- */
      /*                                DELETE DIALOG                               */
      /* -------------------------------------------------------------------------- */}
      <Dialog open={isDeleting} onClose={toggleIsDeleting}>
        <Box style={{ backgroundColor: "rgb(253, 193, 0)" }}>
          <DialogTitle>Delete this contribution?</DialogTitle>
          <DialogActions>
            <Button onClick={toggleIsDeleting}>Cancel</Button>
            <Button
              onClick={deleteContribution}
              // autoFocus
            >
              Delete Contribution
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default HomeItems;
