import React from "react";
import { useHistory } from "react-router-dom";
import { Avatar, Box, Button, Divider, Grid } from "@material-ui/core";
import clsx from "clsx";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WebIcon from "@material-ui/icons/Web";
import Carousel from "./carousel";
import Footer from "../../components/footer";
import Heading from "../../components/heading";
import ContributorForm from "./contributor_form";
import "../../theme/Background.css";

const SignUp = ({ theme }) => {
  const history = useHistory();
  return (
    <Box className={clsx(theme.page, "Background")}>
      <Box
        style={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        <Box m={1}>
          <Avatar
            onClick={() => window.open("https://www.kuulpeeps.com", "_blank")}
            className={theme.bgYellow}
          >
            <WebIcon />
          </Avatar>
        </Box>
        <Box m={1}>
          <Avatar
            onClick={() =>
              window.open(
                "https://web.facebook.com/Kuulpeeps/?_rdc=1&_rdr",
                "_blank"
              )
            }
            className={theme.bgYellow}
          >
            <FacebookIcon />
          </Avatar>
        </Box>
        <Box m={1}>
          <Avatar
            onClick={() =>
              window.open("https://twitter.com/kuulpeeps_", "_blank")
            }
            className={theme.bgYellow}
          >
            <TwitterIcon />
          </Avatar>
        </Box>
        <Box m={1}>
          <Avatar
            onClick={() =>
              window.open("https://www.instagram.com/kuulpeeps/", "_blank")
            }
            className={theme.bgYellow}
          >
            <InstagramIcon />
          </Avatar>
        </Box>
        <Box m={1}>
          <Avatar
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCHFLKPZ6Yt_cofjZy7CZQkA",
                "_blank"
              )
            }
            className={theme.bgYellow}
          >
            <YouTubeIcon />
          </Avatar>
        </Box>
      </Box>
      <Box mt={2}>
        <Carousel />
      </Box>
      {/* <Grid container> */}
      {/* <Grid item xs={4}> */}
      <Box
        py={8}
        // style={{ backgroundColor: "red" }}
      >
        <Heading
          center
          colorA
          variant="h4"
          text="Join us and earn."
          theme={theme}
        />
      </Box>
      <Divider variant="middle" />
      {/* </Grid> */}
      {/* <Grid item xs={8}> */}
      <Box>
        <Box pt={6} pb={4}>
          <Heading
            mobileSpacing
            colorB
            center
            variant="h6"
            text="👋 Sign up here 👇"
            theme={theme}
          />
        </Box>
        <Box pb={8}>
          <ContributorForm />
        </Box>
      </Box>
      <Divider variant="middle" />
      {/* </Grid> */}
      {/* </Grid> */}

      {/* -------------------------------------------------------------------------- */
      /*                                GO TO LOG IN                                */
      /* -------------------------------------------------------------------------- */}

      <Box py={6}>
        <Box pb={1}>
          <Heading variant="h4" colorA center text="Already a contributor?" />
        </Box>
        <Box pb={6}>
          <Heading
            variant="subtitle1"
            colorB
            center
            text="What do you have for us today?"
          />
        </Box>
        <Box px={12}>
          <Button
            onClick={() => history.push("/login")}
            // style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
            fullWidth
            variant="outlined"
            // className="ButtonBackground"
          >
            <Heading center colorB variant="h6" text="Login" />
          </Button>
        </Box>
      </Box>
      <Box pb={10} px={7}>
        <Divider variant="middle" />
      </Box>
      {/* -------------------------------------------------------------------------- */
      /*                                   FOOTER                                   */
      /* -------------------------------------------------------------------------- */}
      <Box>
        <Divider variant="middle" />
        <Footer theme={theme} />
      </Box>
    </Box>
  );
};

export default SignUp;
