import React, { useState, useCallback, useEffect } from "react";
import "fontsource-roboto";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Badge,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import PostAddIcon from "@material-ui/icons/PostAdd";
import NewReleasesIcon from "@material-ui/icons/NewReleases";

import { getPrompts } from "../../axios";

const BottomNav = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const userKey = sessionStorage.getItem("userKey");
  const [keyOn, setKeyOn] = useState(false);
  const location = useLocation();
  const useStyles = makeStyles({
    navRoot: {
      display: !keyOn && "none",
      backgroundColor: "rgb(26, 26, 26)",
      position: "fixed",
      bottom: "0",
      left: isMobile ? "0" : "25vw",
      right: isMobile ? "0" : "25vw",
      borderTop: "4px solid rgb(253, 193, 0)",
      zIndex: "1299",
    },
    icon: {
      color: "rgb(253, 193, 0)",
      width: "40px",
      height: "40px",
    },
    iconBox: {
      paddingTop: "4px",
      paddingBottom: "16px",
    },
  });
  const navTheme = () =>
    createTheme({
      overrides: {
        MuiSvgIcon: {
          root: {
            color: "white",
          },
        },
      },
    });
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const checkKeyOn = useCallback(
    () => userKey && setKeyOn(() => true),
    [userKey]
  );

  useEffect(() => {
    checkKeyOn();
  }, [location.pathname, checkKeyOn]);

  const [prompts, setPrompts] = useState([]);
  const token = sessionStorage.getItem("userKey");

  useEffect(() => {
    (async () => {
      const prompts = await getPrompts(token);
      setPrompts(() => prompts);
    })();
  }, [token]);

  return (
    <Box px={isMobile ? 0 : 48}>
      <MuiThemeProvider theme={navTheme()}>
        <BottomNavigation
          value={value}
          showLabels={false}
          onChange={(event, pathname) => {
            setValue(pathname);
            history.push(`${pathname}`);
          }}
          className={classes.navRoot}
        >
          <BottomNavigationAction
            className={classes.iconBox}
            value="/home"
            icon={<HomeIcon className={classes.icon} />}
          />
          {/* <BottomNavigationAction
          className={classes.iconBox}
          value="/contributions"
          icon={<PostAddIcon className={classes.icon} />}
        /> */}
          <BottomNavigationAction
            className={classes.iconBox}
            value="/prompts"
            icon={
              <Badge
                badgeContent={prompts.length}
                color="secondary"
                overlap="circle"
              >
                <NewReleasesIcon className={classes.icon} />
              </Badge>
            }
          />
          <BottomNavigationAction
            className={classes.iconBox}
            value="/profile"
            icon={<PersonIcon className={classes.icon} />}
          />
        </BottomNavigation>
      </MuiThemeProvider>
    </Box>
  );
};

export default BottomNav;
