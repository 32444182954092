import React from "react";
import clsx from "clsx";
import { Box, Grid, Typography } from "@material-ui/core";

const Footer = ({ theme }) => {
  return (
    <Box
      // pt={12}
      style={{
        // width: "100%",
        height: "60px",
        backgroundColor: "rgba(26, 26, 26, 0)",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "flex-start",
        color: "rgb(253, 193, 0)",
      }}
      pt={2}
    >
      <Typography variant="caption">About</Typography>
      <Typography variant="caption">Terms and Conditions</Typography>
      <Typography variant="caption">Visit Us</Typography>
    </Box>
  );
};

export default Footer;
