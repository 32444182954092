import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Box,
  Backdrop,
  CircularProgress,
  Divider,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import clsx from "clsx";
import Heading from "../../components/heading";
import Footer from "../../components/footer";
import Notification from "../../components/notification";
import "../../theme/Background.css";
const axios = require("axios");

const Login = ({ theme }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const toggleNotification = () => {
    setNotificationOpen((prev) => !prev);
  };
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  }));
  const classes = useStyles();
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should have a minimum 6 characters")
      .required("Password is required"),
  });
  const contributorLogin = async (userInput) => {
    setIsLoading((prev) => !prev);
    const { email, password } = userInput;
    const response = await axios({
      method: "post",
      url: "https://kuulpeeps-contributors-api.herokuapp.com/api/login",
      data: {
        email: email.toLowerCase(),
        password: password,
      },
    });
    if (response.data.data) {
      const {
        _id,
        FirstName,
        LastName,
        Email: responseEmail,
        CallNumber,
        MobileMoney,
        Level,
      } = response.data.data[0];
      sessionStorage.setItem("firstName", FirstName);
      sessionStorage.setItem("lastName", LastName);
      sessionStorage.setItem("email", responseEmail);
      sessionStorage.setItem("callNumber", CallNumber);
      sessionStorage.setItem("mobileMoneyNumber", MobileMoney.Number);
      sessionStorage.setItem("mobileMoneyNetwork", MobileMoney.Network);
      sessionStorage.setItem("level", Level);
      sessionStorage.setItem("userId", _id);
      response.data.token &&
        sessionStorage.setItem("userKey", response.data.token);
      setIsLoading((prev) => !prev);
      return history.push("/home");
    }
    setIsLoading((prev) => !prev);
    toggleNotification();
    console.log(response);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      contributorLogin(values);
    },
  });
  return (
    <Box pt={16} className={clsx(theme.page, "Background")}>
      <Box pb={6}>
        <Heading colorA text="Log in." variant="h4" center theme={theme} />
      </Box>
      <form>
        <Box pb={2} px={6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="email"
            label="Email"
            required
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Box>
        <Box pb={2} px={6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="password"
            label="Password"
            required
            type="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Box>
        <Box px={16} pt={2} pb={8}>
          <Button
            // size="small"
            variant="outlined"
            onClick={formik.handleSubmit}
            // style={{ borderRadius: "16px" }}
            fullWidth
          >
            <Heading colorB text="Submit" variant="subtitle1" center />
          </Button>
        </Box>
        <Box pb={4} px={isMobile ? 10 : 16}>
          <Divider variant="middle" />
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography variant="caption">
              Sign up to become a Contributor.
            </Typography>
          </Link>
        </Box>
      </form>
      <Box style={{ paddingTop: "40vh" }}>
        <Divider variant="middle" />
        <Footer theme={theme} />
      </Box>
      {/* -------------------------------------------------------------------------- */
      /*                               LOADING SCREEN                               */
      /* -------------------------------------------------------------------------- */}

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress style={{ color: "rgb(253, 193, 0)" }} />
      </Backdrop>

      {/* -------------------------------------------------------------------------- */
      /*                                NOTIFICATION                                */
      /* -------------------------------------------------------------------------- */}
      <Notification
        handleClose={toggleNotification}
        message="Incorrect Username/Password"
        open={notificationOpen}
      />
    </Box>
  );
};

export default Login;
