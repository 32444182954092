import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Box from "@material-ui/core/box";
import img1 from "./dark1.jpg";
import img2 from "./dark2.jpg";
import img3 from "./dark3.jpg";

const KuulpeepsCarousel = ({ dimensions }) => {
  const images = [img1, img2, img3];
  const turnOff = () => false;

  return (
    <Box style={{ objectFit: "contain" }}>
      <Carousel
        autoPlay
        infiniteLoop={true}
        renderThumbs={turnOff}
        renderIndicator={turnOff}
        showStatus={false}
        showArrows={false}
      >
        {images.map((item) => {
          return (
            <div key={images.indexOf(item)}>
              <img src={item} alt={images.indexOf(item)} />
            </div>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default KuulpeepsCarousel;
