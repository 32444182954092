import React from "react";
// import "fontsource-roboto";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import Heading from "../heading";

const Banner = ({ pageTitle }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      py={1}
      style={{
        backgroundColor: "rgb(26, 26, 26)",
        borderBottom: "4px solid rgb(253, 193, 0)",
        position: "fixed",
        top: "0",
        left: isMobile ? "0" : "25vw",
        right: isMobile ? "0" : "25vw",
        zIndex: "1299",
      }}
    >
      <Heading colorA center text={pageTitle} variant="h4" />
    </Box>
  );
};

export default Banner;
