import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import clsx from "clsx";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import Banner from "../../components/banner";
import Heading from "../../components/heading";
import Notification from "../../components/notification";
const axios = require("axios");

const Profile = ({ reloadUserData, theme, userData }) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const toggleNotification = () => {
    setNotificationOpen((prev) => !prev);
  };
  const initialValues = {
    FirstName: sessionStorage.getItem("firstName"),
    LastName: sessionStorage.getItem("lastName"),
    Email: sessionStorage.getItem("email"),
    CallNumber: sessionStorage.getItem("callNumber"),
    MobileMoneyNumber: sessionStorage.getItem("mobileMoneyNumber"),
    MobileMoneyNetwork: sessionStorage.getItem("mobileMoneyNetwork"),
    Level: sessionStorage.getItem("level"),
    // Password: "",
    // ConfirmPassword: "",
  };
  const validationSchema = yup.object({
    FirstName: yup
      .string("Enter your first name")
      .min(1, "First Name can not be left empty")
      .required("Email is required"),
    LastName: yup
      .string("Enter your last name")
      .min(1, "Last Name can not be left empty")
      .required("Email is required"),
    Email: yup
      .string("Enter your Email")
      .email("Enter a valid Email address")
      .required("Email is required"),
    CallNumber: yup
      .string("Enter your preferred number for calls")
      .min(1, "Cannot be left blank")
      .required("Call number is required"),
    MobileMoneyNumber: yup
      .string("Enter your preferred number for mobile money payments")
      .min(1, "Cannot be left blank")
      .required("Mobile money number is required"),
    MobileMoneyNetwork: yup
      .string("Enter your mobile money network")
      .min(1, "Cannot be left blank")
      .required("Mobile money network is required"),
    Level: yup
      .string("Enter your Level")
      .min(1, "Cannot be left blank")
      .required(`Please select "none" if you're not a student`),
    Password: yup
      .string("Enter your Password")
      .min(6, "Your Password should be 6 characters or more"),
    //   .required("Please Enter Your Password To Update Account Information"),
    ConfirmPassword: yup
      .string("Password Required")
      .min(6, "Your password should be 6 characters or more")
      .oneOf([yup.ref("Password"), null], "Passwords don't match!"),
    // .required("Password is required"),
  });
  const [base64Data, setBase64Data] = useState(false);
  const loadImage = async (event) => {
    const imageFile = event.target.files[0];
    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    setBase64Data(await toBase64(imageFile));
    console.log(await toBase64(imageFile));
  };
  const updateContributor = async (userInput) => {
    const token = sessionStorage.getItem("userKey");
    const updatedContributorAccount = await axios({
      method: "put",
      url: "https://kuulpeeps-contributors-api.herokuapp.com/api/users",
      headers: { Authorization: token },
      data: {
        ...userInput,
        ConfirmPassword: null,
        MobileMoney: {
          Number: userInput.MobileMoneyNumber,
          Network: userInput.MobileMoneyNetwork,
        },
        ImageUrl: base64Data,
      },
    });
    console.log({
      data: updatedContributorAccount.data.data,
      token: updatedContributorAccount.data.token,
    });
    const { Email, FirstName, LastName, CallNumber, MobileMoney, Level } =
      updatedContributorAccount.data.data[0];
    sessionStorage.setItem("firstName", FirstName);
    sessionStorage.setItem("lastName", LastName);
    sessionStorage.setItem("email", Email);
    sessionStorage.setItem("callNumber", CallNumber);
    sessionStorage.setItem("mobileMoneyNumber", MobileMoney.Number);
    sessionStorage.setItem("mobileMoneyNetwork", MobileMoney.Network);
    sessionStorage.setItem("level", Level);
    sessionStorage.setItem("userKey", updatedContributorAccount.data.token);
    toggleNotification();
    reloadUserData();
  };

  // const updatePassword = async (newPassword) => {
  //   const token = sessionStorage.getItem("userKey");
  //   const updatedContributorAccount = await axios({
  //     method: "put",
  //     url: "https://kuulpeeps-contributors-api.herokuapp.com/api/users",
  //     headers: { Authorization: token },
  //     data: {
  //       Password: newPassword,
  //     },
  //   });
  //   console.log({
  //     data: updatedContributorAccount.data.data,
  //     token: updatedContributorAccount.data.token,
  //   });
  //   toggleNotification();
  // };

  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const toggleIsChangingPassword = () => {
    return setIsChangingPassword((prev) => !prev);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateContributor(values);
    },
  });
  const mobileMoneyNetworks = ["MTN", "Vodafone", "AirtelTigo"];
  const levels = ["N/A", "100", "200", "300", "400"];
  const logOut = () => {
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("lastName");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("callNumber");
    sessionStorage.removeItem("mobileMoneyNumber");
    sessionStorage.removeItem("mobileMoneyNetwork");
    sessionStorage.removeItem("level");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userKey");
    window.location.reload();
  };
  return (
    <Box pt={8} className={clsx(theme.page, "Background")}>
      <Banner pageTitle="Profile" />

      {/* ------------------------------ PAGE CONTENT ------------------------------ */}
      <Box
      // style={{
      //   backgroundColor: "orange",
      //   height: "78vh",
      //   overflow: "scroll",
      // }}
      >
        <Box
          pt={6}
          px={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            required
            type="file"
            size="small"
            fullWidth
            name="imageUrl"
            onChange={loadImage}
            id="image"
            accept="image/x-png,image/gif,image/jpeg"
            style={{ display: "none" }}
          />
          <Badge
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            badgeContent={
              <Box>
                <label htmlFor="image">
                  <IconButton
                    style={{
                      backgroundColor: "rgb(26, 26, 26)",
                      border: "1px solid rgb(253, 193, 0)",
                      color: "rgb(253, 193, 0)",
                      height: "9px",
                      width: "9px",
                    }}
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera
                      style={{
                        color: "rgb(293, 193, 0)",
                        height: "18px",
                        width: "18px",
                      }}
                    />
                  </IconButton>
                </label>
              </Box>
            }
            overlap="circle"
          >
            <Avatar
              src={base64Data || userData.ImageUrl}
              style={{
                color: "rgb(253, 193, 0)",
                backgroundColor: "rgb(36, 36, 36)",
                border: "4px solid rgb(253, 193, 0)",
                height: "100px",
                width: "100px",
              }}
            >
              <Typography variant="h2">
                {sessionStorage.getItem("firstName")?.charAt(0)}
              </Typography>
            </Avatar>
          </Badge>
          <Box pt={2}>
            <Heading
              colorA
              text={`Joined ${moment(userData.DateJoined).format(
                "ddd, MMMM Do YYYY"
              )}`}
              variant="caption"
            />
          </Box>
        </Box>
        <Box pt={6} px={1}>
          <Divider variant="middle" />
        </Box>

        <Box pt={4}>
          <form>
            <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                fullWidth
                name="FirstName"
                label="First Name"
                onChange={formik.handleChange}
                value={formik.values.FirstName}
                error={
                  formik.touched.FirstName && Boolean(formik.errors.FirstName)
                }
                helperText={formik.touched.FirstName && formik.errors.FirstName}
              />
            </Box>
            <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                fullWidth
                name="LastName"
                label="Last Name"
                onChange={formik.handleChange}
                value={formik.values.LastName}
                error={
                  formik.touched.LastName && Boolean(formik.errors.LastName)
                }
                helperText={formik.touched.LastName && formik.errors.LastName}
              />
            </Box>
            <Box px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                fullWidth
                name="Email"
                label="Email"
                onChange={formik.handleChange}
                value={formik.values.Email}
                error={formik.touched.Email && Boolean(formik.errors.Email)}
                helperText={formik.touched.Email && formik.errors.Email}
              />
            </Box>
            <Box pb={4} pt={4} px={4}>
              <Divider variant="middle" />
            </Box>
            <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                fullWidth
                name="CallNumber"
                label="Call Number"
                onChange={formik.handleChange}
                value={formik.values.CallNumber}
                error={
                  formik.touched.CallNumber && Boolean(formik.errors.CallNumber)
                }
                helperText={
                  formik.touched.CallNumber && formik.errors.CallNumber
                }
              />
            </Box>
            <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                fullWidth
                name="MobileMoneyNumber"
                label="Money Mobile Number"
                onChange={formik.handleChange}
                value={formik.values.MobileMoneyNumber}
                error={
                  formik.touched.MobileMoneyNumber &&
                  Boolean(formik.errors.MobileMoneyNumber)
                }
                helperText={
                  formik.touched.MobileMoneyNumber &&
                  formik.errors.MobileMoneyNumber
                }
              />
            </Box>
            <Box px={6}>
              <TextField
                variant="outlined"
                select
                children={mobileMoneyNetworks.map((network) => {
                  return (
                    <MenuItem
                      key={mobileMoneyNetworks.indexOf(network)}
                      value={network}
                    >
                      {network}
                    </MenuItem>
                  );
                })}
                required
                size="small"
                fullWidth
                name="MobileMoneyNetwork"
                label="Mobile Money Networks"
                onChange={formik.handleChange}
                value={formik.values.MobileMoneyNetwork}
                error={
                  formik.touched.MobileMoneyNetwork &&
                  Boolean(formik.errors.MobileMoneyNetwork)
                }
                helperText={
                  formik.touched.MobileMoneyNetwork &&
                  formik.errors.MobileMoneyNetwork
                }
              />
            </Box>
            <Box pb={4} pt={4} px={4}>
              <Divider variant="middle" />
            </Box>
            <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                select
                children={levels.map((level) => {
                  return (
                    <MenuItem key={levels.indexOf(level)} value={level}>
                      {level}
                    </MenuItem>
                  );
                })}
                required
                size="small"
                fullWidth
                name="Level"
                label="Level"
                onChange={formik.handleChange}
                value={formik.values.Level}
                error={formik.touched.Level && Boolean(formik.errors.Level)}
                helperText={formik.touched.Level && formik.errors.Level}
              />
            </Box>
            {/* <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                type="password"
                fullWidth
                name="Password"
                label="Password"
                onChange={formik.handleChange}
                value={formik.values.Password}
                error={
                  formik.touched.Password && Boolean(formik.errors.Password)
                }
                helperText={formik.touched.Password && formik.errors.Password}
              />
            </Box> */}
            {/* <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                type="password"
                fullWidth
                name="ConfirmPassword"
                label="Confirm Password"
                onChange={formik.handleChange}
                value={formik.values.ConfirmPassword}
                error={
                  formik.touched.ConfirmPassword &&
                  Boolean(formik.errors.ConfirmPassword)
                }
                helperText={
                  formik.touched.ConfirmPassword &&
                  formik.errors.ConfirmPassword
                }
              />
            </Box> */}
            <Box py={2} px={6}>
              <Button
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0)",
                }}
                onClick={formik.handleSubmit}
                fullWidth
                // size="small"
                variant="outlined"
              >
                <b>Update Details</b>
              </Button>
            </Box>
          </form>
          <Box py={2} px={6}>
            <Button
              style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
              }}
              onClick={toggleIsChangingPassword}
              fullWidth
              // size="small"
              variant="outlined"
            >
              <b>Change Password</b>
            </Button>
          </Box>
        </Box>
        <Box pb={4} pt={4} px={2}>
          <Divider variant="middle" />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={logOut}
            style={{
              backgroundColor: "rgb(36, 36, 36)",
              border: "4px solid rgb(253, 193, 0)",
            }}
          >
            <PowerSettingsNewIcon
              style={{
                // width: "28px",
                // height: "28px",
                color: "rgb(253, 193, 0)",
              }}
            />
          </IconButton>
          <Heading colorA text="Log out" variant="caption" />
        </Box>
        <Box pb={8} pt={4} px={4}>
          {/* <Divider variant="middle" /> */}
        </Box>
      </Box>

      {/* -------------------------------------------------------------------------- */
      /*                        IS CHANGING PASSWORD DIALOGUE                       */
      /* -------------------------------------------------------------------------- */}
      <Dialog open={isChangingPassword} onClose={toggleIsChangingPassword}>
        <Box style={{ backgroundColor: "rgb(253, 193, 0)" }}>
          <DialogTitle style={{ textAlign: "center" }}>
            Enter Your New Password
          </DialogTitle>
          <DialogContent>
            <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                type="password"
                fullWidth
                name="Password"
                label="Password"
                onChange={formik.handleChange}
                value={formik.values.Password}
                error={
                  formik.touched.Password && Boolean(formik.errors.Password)
                }
                helperText={formik.touched.Password && formik.errors.Password}
              />
            </Box>
            <Box pb={2} px={6}>
              <TextField
                variant="outlined"
                required
                size="small"
                type="password"
                fullWidth
                name="ConfirmPassword"
                label="Confirm Password"
                onChange={formik.handleChange}
                value={formik.values.ConfirmPassword}
                error={
                  formik.touched.ConfirmPassword &&
                  Boolean(formik.errors.ConfirmPassword)
                }
                helperText={
                  formik.touched.ConfirmPassword &&
                  formik.errors.ConfirmPassword
                }
              />
            </Box>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={toggleIsChangingPassword}>Cancel</Button>
            <Button
              onClick={() => {
                formik.handleSubmit();
                toggleIsChangingPassword();
                return;
              }}
            >
              Update Password
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* -------------------------------------------------------------------------- */
      /*                                NOTIFICATION                                */
      /* -------------------------------------------------------------------------- */}
      <Notification
        success
        handleClose={toggleNotification}
        message="Account Updated"
        open={notificationOpen}
      />
    </Box>
  );
};

export default Profile;
