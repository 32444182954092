import axios from "axios";

export const getPrompts = async (token) => {
  const response = await axios({
    method: "get",
    url: "https://kuulpeeps-contributors-api.herokuapp.com/api/prompts",
    headers: { Authorization: token },
  });
  console.log(response.data.data);
  return response.data.data;
};
