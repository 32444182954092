import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CloseIcon from "@material-ui/icons/Close";
// import "../../../Background.css";
import "../../theme/Background.css";
import Heading from "../../components/heading";
import Notification from "../../components/notification";
const axios = require("axios");

const NewContributionScreen = ({
  isMakingContribution,
  toggleScreen,
  userData,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [notificationOpen, setNotificationOpen] = useState(false);
  const toggleNotification = () => {
    setNotificationOpen((prev) => !prev);
  };
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const toggleSubmit = () => {
    setButtonDisabled((prev) => !prev);
  };
  const initialValues = {
    Title: "",
    Category: "",
    ArticleContent: "",
    IsAnonymous: "",
  };
  const validationSchema = yup.object({
    Title: yup
      .string("Enter your email")
      .min(20, "Title is too short")
      .required("Email is required"),
    Category: yup
      .string("Enter your password")
      .min(6, "Password should have a minimum 6 characters")
      .required("Password is required"),
    ArticleContent: yup
      .string("Enter your password")
      .min(200, "Your article is too short")
      .required("Password is required"),
    IsAnonymous: yup
      .string("Enter your password")
      .required("Password is required"),
  });
  const [base64Array, setBase64Array] = useState([]);
  const loadImages = async (event) => {
    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    const imageFiles = event.target.files;
    const conversionArray = [...base64Array];
    for (var i = 0; i < imageFiles.length; i++) {
      const file = imageFiles[i];
      const thisImage = await toBase64(file);
      conversionArray.push(thisImage);
    }
    console.log(conversionArray);
    setBase64Array(() => conversionArray);
  };
  const createContribution = async (values) => {
    toggleSubmit();
    toggleNotification();
    const token = sessionStorage.getItem("userKey");
    const isAnonymous = () => {
      if (values.IsAnonymous === "true") {
        return true;
      }
      return false;
    };
    const contributionCreated = await axios({
      method: "post",
      url: "https://kuulpeeps-contributors-api.herokuapp.com/api/contributions",
      headers: { Authorization: token },
      data: {
        ...values,
        ContributorId: userData._id,
        ContributorFirstName: userData.FirstName,
        ContributorLastName: userData.LastName,
        ContributorCampus: userData.Campus,
        IsAnonymous: isAnonymous(),
        ImageLinks: base64Array.slice(0, 10),
      },
    });
    console.log(contributionCreated);
    toggleScreen();
    toggleSubmit();
    window.location.reload();
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createContribution(values);
    },
  });
  const categories = [
    "People",
    "Hustle And Hacks",
    "Campus",
    "Pop Culture",
    "Trending",
    "Listicles",
  ];
  const nameOptions = [
    { name: "Anonymous", value: "true" },
    { name: `${userData.FirstName} ${userData.LastName}`, value: "false" },
  ];
  return (
    <Modal
      style={{
        zIndex: "1300",
        padding: isMobile ? "0" : "0 25vw",
      }}
      open={isMakingContribution}
      hideBackdrop={false}
    >
      <Box>
        {/* -------------------------------------------------------------------------- */
        /*                            CLOSE AND SEND PANEL                            */
        /* -------------------------------------------------------------------------- */}
        <Box
          style={{
            // backgroundColor: "red",
            position: "fixed",
            top: 0,
            display: "flex",
          }}
        >
          <Box
            pl={1}
            pt={2}
            style={{
              // backgroundColor: "green",
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <CloseIcon
              onClick={toggleScreen}
              fontSize="large"
              style={{
                color: "rgb(253, 193, 0)",
                // backgroundColor: "red",
              }}
            />
          </Box>
          {/* <Box
            pr={4}
            style={{
              backgroundColor: "blue",
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                border: "3px solid rgb(253, 193, 0)",
                borderRadius: "16px",
                color: "rgb(253, 193, 0)",
                backgroundColor: "rgb(36, 36, 36)",
              }}
              onClick={formik.handleSubmit}
              size="small"
              variant="outlined"
            >
              <b>Send</b>
            </Button>
          </Box> */}
        </Box>
        {/* -------------------------------------------------------------------------- */
        /*                                 EDITOR BOX                                 */
        /* -------------------------------------------------------------------------- */}
        <Box
          mt={8}
          mx="auto"
          //   pt={8}
          //   px={3}
          style={{
            overflow: "scroll",
            width: "88%",
            height: "80vh",
            border: "1px solid rgb(36, 36, 36)",
            borderRadius: "6px",
          }}
          className="Background"
        >
          <Box
            pt={3}
            //  pb={2}
          >
            <Heading center variant="h6" text={"New".toUpperCase()} colorA />
            <Heading
              center
              variant="caption"
              text="Fill in the contribution details and hit send"
              colorB
            />
          </Box>
          <Box
            pt={2}
            pb={3}
            //  px={2}
          >
            <Divider variant="middle" />
          </Box>
          <form>
            <Box pb={2} px={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="Title"
                label="Headline"
                required
                name="Title"
                onChange={formik.handleChange}
                value={formik.values.Title}
                error={formik.touched.Title && Boolean(formik.errors.Title)}
                helperText={formik.touched.Title && formik.errors.Title}
              />
            </Box>

            <Box pb={2} px={3}>
              <TextField
                variant="outlined"
                select
                children={categories.map((category) => {
                  return (
                    <MenuItem
                      key={categories.indexOf(category)}
                      value={category}
                    >
                      {category}
                    </MenuItem>
                  );
                })}
                required
                size="small"
                fullWidth
                name="Category"
                label="Category"
                onChange={formik.handleChange}
                value={formik.values.Category}
                error={
                  formik.touched.Category && Boolean(formik.errors.Category)
                }
                helperText={formik.touched.Category && formik.errors.Category}
              />
            </Box>
            <Box pb={2} px={3}>
              <TextField
                spellcheck="true"
                multiline
                rows={5}
                variant="outlined"
                size="small"
                fullWidth
                id="ArticleContent"
                label="Article Body"
                required
                name="ArticleContent"
                onChange={formik.handleChange}
                value={formik.values.ArticleContent}
                error={
                  formik.touched.ArticleContent &&
                  Boolean(formik.errors.ArticleContent)
                }
                helperText={
                  formik.touched.ArticleContent && formik.errors.ArticleContent
                }
              />
            </Box>
            <Box pt={base64Array.length !== 0 ? 2 : 0} px={3}>
              <Grid container spacing={1}>
                {base64Array.slice(0, 10).map((base64ImageSrc) => {
                  return (
                    <Grid
                      key={base64Array.indexOf(base64ImageSrc)}
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          src={base64ImageSrc}
                          style={{
                            border: "2px solid rgb(253, 193, 0)",
                            borderRadius: "4px",
                            width: "100px",
                            height: "100px",
                          }}
                          variant="square"
                        />
                        <Box
                          pb={base64Array.indexOf(base64ImageSrc) !== 4 ? 1 : 0}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            // size="small"
                            onClick={() => {
                              const toUpload = [...base64Array];
                              toUpload.splice(
                                toUpload.indexOf(base64ImageSrc),
                                1
                              );
                              return setBase64Array(toUpload);
                            }}
                          >
                            <DeleteForeverIcon
                              fontSize="small"
                              style={{ color: "rgb(253, 193, 0)" }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <Box pb={2} px={3}>
              <input
                multiple
                type="file"
                name="imageLoader"
                onChange={loadImages}
                id="image"
                accept="image/x-png,image/gif,image/jpeg"
                style={{ display: "none" }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label htmlFor="image">
                  <IconButton
                    style={{ color: "rgb(253, 193, 0)" }}
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera style={{ color: "rgb(293, 193, 0)" }} />
                  </IconButton>
                </label>
                <Heading variant="caption" colorA text="UPLOAD (MAX. 10)" />
              </Box>
            </Box>
            <Box pb={2} px={3}>
              <TextField
                variant="outlined"
                select
                children={nameOptions.map((option) => {
                  return (
                    <MenuItem
                      key={nameOptions.indexOf(option)}
                      value={option.value}
                    >
                      {option.name}
                    </MenuItem>
                  );
                })}
                required
                size="small"
                fullWidth
                name="IsAnonymous"
                label="Publish As"
                onChange={formik.handleChange}
                value={formik.values.IsAnonymous}
                error={
                  formik.touched.IsAnonymous &&
                  Boolean(formik.errors.IsAnonymous)
                }
                helperText={
                  formik.touched.IsAnonymous && formik.errors.IsAnonymous
                }
              />
            </Box>
            <Box pb={2} px={3}>
              <Button
                disabled={buttonDisabled}
                variant="outlined"
                onClick={formik.handleSubmit}
                fullWidth
              >
                <Heading colorB text="Submit" variant="subtitle1" center />
              </Button>
            </Box>
          </form>
          <Box
            pt={2}
            pb={2}
            //  px={2}
          >
            <Divider variant="middle" />
          </Box>
          <Box px={4} pb={4}>
            <Heading
              center
              colorB
              text="Uploading images relevant to your post will greatly increase the chance of your contribution being approved"
              variant="caption"
            />
          </Box>
        </Box>
        {/* -------------------------------------------------------------------------- */
        /*                                NOTIFICATION                                */
        /* -------------------------------------------------------------------------- */}
        <Notification
          handleClose={toggleNotification}
          message="Uploading Contribution"
          open={notificationOpen}
        />
      </Box>
    </Modal>
  );
};

export default NewContributionScreen;
