import React, { useState, useEffect } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import Banner from "../../components/banner";
import Heading from "../../components/heading";
import ListItem from "../../components/list_item";
import "../../theme/Background.css";
import moment from "moment";
// const axios = require("axios");
import {getPrompts} from "../../axios"

const Prompts = ({ theme, toggleIsMakingContribution }) => {
  const [prompts, setPrompts] = useState([]);
  const token = sessionStorage.getItem("userKey");

  useEffect(() => {
    (async () => {
      const prompts = await getPrompts(token)
      setPrompts(() => prompts);
    })();
  }, [token]);

  return (
    <Box className={clsx(theme.page, "Background")}>
      <Banner pageTitle="Prompts" />

      {/* ------------------------------ PAGE CONTENT ------------------------------ */}
      <Box pt={14} px={2}>
        <Box pb={1}>
          <Heading
            center
            colorB
            text="Special orders from your Editor-in-Chief"
            variant="h4"
          />
        </Box>
        <Heading center colorA text="Earn more!" variant="h5" />
      </Box>
      <Box pt={4}>
        <Divider variant="middle" />
      </Box>
      <Box pb={8} pt={1} px={2}>
        {prompts.length === 0 && (
          <ListItem listTextB="There are currently no available prompts" />
        )}
        {prompts.map((prompt) => {
          return (
            <ListItem
              action={toggleIsMakingContribution}
              borderB
              iconA
              key={prompts.indexOf(prompt)}
              listTextA={prompt.Category}
              listTextB={prompt.Prompt}
              listTextC={moment(prompt.DateCreated).format(
                "ddd, MMMM Do YYYY, H:mm"
              )}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Prompts;
