import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  MobileStepper,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useFormik } from "formik";
import * as yup from "yup";
import Heading from "../../../components/heading";

const axios = require("axios");

const SignUpForm = ({ theme: globalTheme }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isLoading, setIsLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    callNumber: "",
    mobileMoneyNumber: "",
    mobileMoneyNetwork: "",
    campus: "",
    level: "",
    password: "",
    confirmPassword: "",
    // imageUrl: "test.png",
  };
  const validationSchema = yup.object({
    firstName: yup
      .string("Enter your first name")
      .min(1, "First Name can not be left empty")
      .required("Email is required"),
    lastName: yup
      .string("Enter your last name")
      .min(1, "Last Name can not be left empty")
      .required("Email is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email address")
      .required("Email is required"),
    callNumber: yup
      .string("Enter your preferred number for calls")
      .min(1, "Cannot be left blank")
      .required("Call number is required"),
    mobileMoneyNumber: yup
      .string("Enter your preferred number for mobile money payments")
      .min(1, "Cannot be left blank")
      .required("Mobile money number is required"),
    mobileMoneyNetwork: yup
      .string("Enter your mobile money network")
      .min(1, "Cannot be left blank")
      .required("Mobile money network is required"),
    campus: yup
      .string("Enter your email")
      .min(1, "Cannot be left blank")
      .required("Email is required"),
    level: yup
      .string("Enter your level")
      .min(1, "Cannot be left blank")
      .required(`Please select "none" if you're not a student`),
    password: yup
      .string("Enter your password")
      .min(6, "Your password should be 6 characters or more")
      .required("Password is required"),
    confirmPassword: yup
      .string("Password Required")
      .min(6, "Your password should be 6 characters or more")
      .oneOf([yup.ref("password"), null], "Passwords don't match!")
      .required("Password is required"),
  });
  const history = useHistory();
  const [base64Data, setBase64Data] = useState(false);
  const loadImage = async (event) => {
    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    const imageFile = event.target.files[0];
    setBase64Data(await toBase64(imageFile));
    console.log(await toBase64(imageFile));
  };
  const createContributor = async (userInput) => {
    setIsLoading(() => true);
    console.log(userInput);
    const {
      firstName,
      lastName,
      email,
      callNumber,
      mobileMoneyNumber,
      mobileMoneyNetwork,
      campus,
      level,
      password,
    } = userInput;
    const newContributorAccount = await axios({
      method: "post",
      url: "https://kuulpeeps-contributors-api.herokuapp.com/api/users",
      data: {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        HashedPassword: password,
        CallNumber: callNumber,
        MobileMoney: {
          Number: mobileMoneyNumber,
          Network: mobileMoneyNetwork,
        },
        Level: level,
        Campus: campus,
        ImageUrl: base64Data,
      },
    });
    console.log({
      data: newContributorAccount.data.data,
      token: newContributorAccount.data.token,
    });
    const { _id, Email, FirstName, LastName } = newContributorAccount.data.data;
    sessionStorage.setItem("firstName", FirstName);
    sessionStorage.setItem("lastName", LastName);
    sessionStorage.setItem("email", Email);
    sessionStorage.setItem("userId", _id);
    sessionStorage.setItem("userKey", newContributorAccount.data.token);
    history.push("/home");
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createContributor(values);
    },
  });
  const mobileMoneyNetworks = ["MTN", "Vodafone", "AirtelTigo"];
  const campuses = [
    "N/A",
    "Ashesi",
    "ATU",
    "CCTU",
    "CU",
    "CUC",
    "CUCG",
    "KNUST",
    "Legon",
    "TTU",
    "UCC",
    "UDS",
    "UEW",
    "UENER",
    "WUIC",
  ];
  const levels = ["N/A", "100", "200", "300", "400"];
  return (
    <Box>
      {/* -------------------------------------------------------------------------- */
      /*                                 FORM FIELDS                                 */
      /* -------------------------------------------------------------------------- */}

      <Box style={{ height: "288px" }}>
        {activeStep === 0 && (
          <Box>
            <form>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  name="firstName"
                  label="First Name"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Box>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Box>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  name="email"
                  label="Email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  select
                  children={campuses.map((campus) => {
                    return (
                      <MenuItem key={campuses.indexOf(campus)} value={campus}>
                        {campus}
                      </MenuItem>
                    );
                  })}
                  required
                  size="small"
                  fullWidth
                  name="campus"
                  label="Campus"
                  onChange={formik.handleChange}
                  value={formik.values.campus}
                  error={formik.touched.campus && Boolean(formik.errors.campus)}
                  helperText={formik.touched.campus && formik.errors.campus}
                />
              </Box>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  select
                  children={levels.map((level) => {
                    return (
                      <MenuItem key={levels.indexOf(level)} value={level}>
                        {level}
                      </MenuItem>
                    );
                  })}
                  required
                  size="small"
                  fullWidth
                  name="level"
                  label="Level"
                  onChange={formik.handleChange}
                  value={formik.values.level}
                  error={formik.touched.level && Boolean(formik.errors.level)}
                  helperText={formik.touched.level && formik.errors.level}
                />
              </Box>
            </form>
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <form>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  name="callNumber"
                  label="Call Number"
                  onChange={formik.handleChange}
                  value={formik.values.callNumber}
                  error={
                    formik.touched.callNumber &&
                    Boolean(formik.errors.callNumber)
                  }
                  helperText={
                    formik.touched.callNumber && formik.errors.callNumber
                  }
                />
              </Box>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  name="mobileMoneyNumber"
                  label="Mobile Money Number"
                  onChange={formik.handleChange}
                  value={formik.values.mobileMoneyNumber}
                  error={
                    formik.touched.mobileMoneyNumber &&
                    Boolean(formik.errors.mobileMoneyNumber)
                  }
                  helperText={
                    formik.touched.mobileMoneyNumber &&
                    formik.errors.mobileMoneyNumber
                  }
                />
              </Box>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  select
                  children={mobileMoneyNetworks.map((network) => {
                    return (
                      <MenuItem
                        key={mobileMoneyNetworks.indexOf(network)}
                        value={network}
                      >
                        {network}
                      </MenuItem>
                    );
                  })}
                  required
                  size="small"
                  fullWidth
                  name="mobileMoneyNetwork"
                  label="Mobile Money Network"
                  onChange={formik.handleChange}
                  value={formik.values.mobileMoneyNetwork}
                  error={
                    formik.touched.mobileMoneyNetwork &&
                    Boolean(formik.errors.mobileMoneyNetwork)
                  }
                  helperText={
                    formik.touched.mobileMoneyNetwork &&
                    formik.errors.mobileMoneyNetwork
                  }
                />
              </Box>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  type="password"
                  fullWidth
                  name="password"
                  label="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Box>
              <Box pb={2} px={6}>
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  type="password"
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </Box>
            </form>
          </Box>
        )}
        {activeStep === 2 && (
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <form>
              <TextField
                required
                type="file"
                size="small"
                fullWidth
                name="imageUrl"
                onChange={loadImage}
                id="image"
                accept="image/x-png,image/gif,image/jpeg"
                style={{ display: "none" }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box pt={2}>
                  <Badge
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    badgeContent={
                      <Box>
                        <label htmlFor="image">
                          <IconButton
                            style={{
                              backgroundColor: "rgb(26, 26, 26)",
                              border: "1px solid rgb(253, 193, 0)",
                              color: "rgb(253, 193, 0)",
                              height: "9px",
                              width: "9px",
                            }}
                            aria-label="upload picture"
                            component="span"
                          >
                            <PhotoCamera
                              style={{
                                color: "rgb(293, 193, 0)",
                                height: "18px",
                                width: "18px",
                              }}
                            />
                          </IconButton>
                        </label>
                      </Box>
                    }
                    overlap="circle"
                  >
                    <Avatar
                      style={{
                        color: "rgb(253, 193, 0)",
                        backgroundColor: "rgb(36, 36, 36)",
                        border: "4px solid rgb(253, 193, 0)",
                        height: "150px",
                        width: "150px",
                      }}
                      // style={{
                      //   height: "200px",
                      //   width: "200px",
                      //   // border: "1px solid rgba(0, 0, 0, 0.2)",
                      //   // borderRadius: "4px",
                      //   backgroundColor: "rgba(0, 0, 0, 0)",
                      // }}
                      // variant="square"
                      src={base64Data}
                    />
                  </Badge>
                </Box>
                <Box py={4} px={isMobile ? 12 : 32}>
                  <Heading
                    colorB
                    center
                    variant="subtitle2"
                    text={"Upload a profile image to complete your sign up!"}
                  />
                </Box>
              </Box>
            </form>
          </Box>
        )}
      </Box>

      {/* -------------------------------------------------------------------------- */
      /*                               FORM PAGINATION                              */
      /* -------------------------------------------------------------------------- */}
      <Box px={8} pt={1}>
        <Divider variant="fullWidth" />
        <Box py={1}>
          <MobileStepper
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            steps={3}
            position="static"
            variant="dots"
            activeStep={activeStep}
            nextButton={
              activeStep < 2 ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleNext}
                  // disabled={activeStep === 2}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={formik.handleSubmit}
                >
                  Done!
                </Button>
              )
            }
            backButton={
              <Button
                variant="outlined"
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                Back
              </Button>
            }
          />
        </Box>
        <Divider variant="fullWidth" />
      </Box>

      {/* -------------------------------------------------------------------------- */
      /*                               LOADING SCREEN                               */
      /* -------------------------------------------------------------------------- */}

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress style={{ color: "rgb(253, 193, 0)" }} />
      </Backdrop>
      {/* </Box> */}
    </Box>
  );
};

export default SignUpForm;
