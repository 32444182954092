import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import CreateIcon from "@material-ui/icons/Create";

const ListItem = ({
  action,
  borderB,
  deletable,
  hasRejection,
  iconA,
  iconB,
  listTextA,
  listTextB,
  listTextC,
  listTextD,
  listTextE,
}) => {
  return (
    <Box pb={1} onClick={action}>
      <Box
        mb={1}
        pl={1}
        style={{
          borderLeft: borderB
            ? "4px solid rgb(36, 36, 36)"
            : "4px solid rgb(253, 193, 0)",
        }}
      >
        {listTextA && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            {iconA && (
              <Box pr={1} style={{ order: 1, alignSelf: "flex-end" }}>
                <CreateIcon
                  style={{
                    color: "rgb(253, 193, 9)",
                  }}
                />
              </Box>
            )}
            {iconB && (
              <Box pr={1} style={{ order: 1, alignSelf: "flex-end" }}>
                <OpenInBrowserIcon
                  style={{
                    color: "rgb(253, 193, 9)",
                  }}
                />
              </Box>
            )}
            <Typography variant="h6" style={{ display: "inline", order: 1 }}>
              {listTextA}
            </Typography>
            {deletable && (
              <Box component="span" style={{ order: 2 }}>
                <Typography
                  variant="caption"
                  style={{
                    display: "inline",
                    color: "red",
                    paddingLeft: "2px",
                  }}
                >
                  <i>Tap to delete this contribution</i>
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Box>
          {listTextB && (
            <Typography style={{ display: "inline" }} variant="subtitle1">
              {listTextB}
            </Typography>
          )}
          {listTextC && (
            <Typography
              style={{ display: "inline", color: "#A4A4A4" }}
              variant="subtitle1"
            >
              {" "}
              — {listTextC}
            </Typography>
          )}
        </Box>
        <Box>
          {listTextD && (
            <Typography
              style={{ display: "inline", color: hasRejection && "red" }}
              variant={hasRejection ? "caption" : "subtitle1"}
            >
              {listTextD}
            </Typography>
          )}
          {listTextE && (
            <Typography
              style={{ display: "inline", color: "#A4A4A4" }}
              variant="subtitle1"
            >
              {" "}
              — {listTextE}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider variant="fullWidth" />
    </Box>
  );
};

export default ListItem;
