import React from "react";
import { Box, Typography } from "@material-ui/core";

const Header = ({
  colorA,
  colorB,
  colorC,
  colorD,
  text,
  center,
  variant,
  lighter,
  hasShadow,
}) => {
  return (
    <Box style={{ textAlign: center && "center" }}>
      <Typography
        style={{
          color:
            (colorA && "rgb(253, 193, 0)") ||
            (colorB && "rgb(36, 36, 36)") ||
            (colorC && "white") ||
            (colorD && "#A4A4A4"),
          textShadow: hasShadow && "1px 1px 0 rgb(253, 193, 0)",
        }}
        // className={isBlack ? false : theme.textYellow}
        variant={variant}
      >
        {lighter ? text : <b>{text}</b>}
      </Typography>
    </Box>
  );
};

export default Header;
