import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Fab,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WebIcon from "@material-ui/icons/Web";
import clsx from "clsx";

import Banner from "../../components/banner";
import Heading from "../../components/heading";
import HomeItems from "./home_items";
import "../../theme/Background.css";
import * as Utils from "../../utils";
const axios = require("axios");

const Home = ({
  reloadUserData,
  toggleIsMakingContribution,
  theme,
  userData,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  const email = sessionStorage.getItem("email");
  useEffect(() => {
    reloadUserData();
  }, [reloadUserData]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  useEffect(() => {
    const token = sessionStorage.getItem("userKey");
    const getPaymentHistory = async () => {
      const paymentHistory = await axios({
        method: "get",
        url: "https://kuulpeeps-contributors-api.herokuapp.com/api/logs",
        headers: { Authorization: token },
      });
      console.log({ paymentHistory: paymentHistory.data.data });
      setPaymentHistory(() => paymentHistory.data.data);
    };
    (async () => {
      await getPaymentHistory();
    })();
  }, []);
  const [contributions, setContributions] = useState([]);
  useEffect(() => {
    const token = sessionStorage.getItem("userKey");
    const id = sessionStorage.getItem("userId");
    const getContributions = async () => {
      const userContributions = await axios({
        method: "get",
        url: `https://kuulpeeps-contributors-api.herokuapp.com/api/contributions/?${Utils.toQueryString(
          {
            ContributorId: id,
          }
        )}`,
        headers: { Authorization: token },
      });
      console.log({ contributions: userContributions.data.data });
      setContributions(() => userContributions.data.data);
    };
    (async () => {
      await getContributions();
    })();
  }, []);
  return (
    <Box pb={8} className={clsx(theme.page, "Background")}>
      <Banner pageTitle="Home" />
      <Box pt={16} pb={2}>
        <Heading
          colorA
          center
          variant="h3"
          text={`Welcome, ${firstName} ${lastName}.`}
        />
      </Box>
      <Box>
        <Heading colorB center variant="subtitle1" text={email} />
      </Box>
      <Box pt={5} pb={5}>
        <Divider variant="middle" />
      </Box>
      <Box pb={5}>
        <Heading
          colorB
          center
          variant="subtitle2"
          text={`Cashed Out: GHC${
            userData.length !== 0 ? userData.PaidBalance?.toFixed(2) : " "
          } || Balance: GHC${
            userData.length !== 0 ? userData.UnpaidBalance?.toFixed(2) : " "
          }`}
        />
      </Box>
      <Box pb={6}>
        <Divider variant="middle" />
      </Box>

      {/* -------------------------------------------------------------------------- */
      /*                               ACCORDION GROUP                              */
      /* -------------------------------------------------------------------------- */}

      <Box px={4}>
        <HomeItems
          userData={userData}
          paymentHistory={paymentHistory}
          contributions={contributions}
        />
      </Box>
      <Box pt={8} pb={4}>
        <Divider variant="middle" />
      </Box>

      {/* -------------------------------------------------------------------------- */
      /*                               EXTERNAL LINKS                               */
      /* -------------------------------------------------------------------------- */}
      <Box
        py={2}
        style={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        <Box m={1}>
          <Avatar
            onClick={() => window.open("https://www.kuulpeeps.com", "_blank")}
            className={theme.bgYellow}
          >
            <WebIcon />
          </Avatar>
        </Box>
        <Box m={1}>
          <Avatar
            onClick={() =>
              window.open(
                "https://web.facebook.com/Kuulpeeps/?_rdc=1&_rdr",
                "_blank"
              )
            }
            className={theme.bgYellow}
          >
            <FacebookIcon />
          </Avatar>
        </Box>
        <Box m={1}>
          <Avatar
            onClick={() =>
              window.open("https://twitter.com/kuulpeeps_", "_blank")
            }
            className={theme.bgYellow}
          >
            <TwitterIcon />
          </Avatar>
        </Box>
        <Box m={1}>
          <Avatar
            onClick={() =>
              window.open("https://www.instagram.com/kuulpeeps/", "_blank")
            }
            className={theme.bgYellow}
          >
            <InstagramIcon />
          </Avatar>
        </Box>
        <Box m={1}>
          <Avatar
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCHFLKPZ6Yt_cofjZy7CZQkA",
                "_blank"
              )
            }
            className={theme.bgYellow}
          >
            <YouTubeIcon />
          </Avatar>
        </Box>
      </Box>

      <Box pb={10}>
        <Heading
          colorB
          center
          variant="subtitle2"
          text={"Visit Kuulpeeps on other channels"}
        />
      </Box>
      {/* -------------------------------------------------------------------------- */
      /*                           FLOATING ACTION BUTTON                           */
      /* -------------------------------------------------------------------------- */}

      <Box
        pb={10}
        style={{
          // backgroundColor: "red",
          display: "flex",
          justifyContent: "flex-end",
          position: "fixed",
          bottom: "0",
          left: "0",
          right: isMobile ? "16px" : "26vw",
        }}
      >
        <Fab
          onClick={() => toggleIsMakingContribution()}
          style={{ backgroundColor: "rgb(253, 193, 0)" }}
        >
          <AddIcon style={{ color: "rgb(26, 26, 26)" }} />
        </Fab>
      </Box>
    </Box>
  );
};

export default Home;
