import React from "react";
import { Slide, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const Notification = ({ handleClose, message, open, success }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={(props) => <Slide {...props} direction="left" />}
    >
      <Alert
        severity={success ? "success" : "info"}
        elevation={6}
        variant="filled"
        style={{ backgroundColor: "rgb(253, 193, 0)" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
